import React, { useState, useEffect } from "react";
import { Button, Card, Form, Input, Select, Switch } from "antd";
import { RiCoinsLine } from "react-icons/ri";
import EUR from "./assets/EUR.png";
import USD from "./assets/USD.png";
import GBP from "./assets/GBP.png";
import "./Converter.css";
const currencyFlags = {
  USD: USD,
  EUR: EUR,
  GBP: GBP,
};

const fiatList = [
  { value: "USD", label: "US Dollar" },
  { value: "EUR", label: "Euro" },
  { value: "GBP", label: "British Pound" },
  { value: "TRY", label: "Turkish Lira" },
];

const cryptoList = [
  { value: "USDT(trc20)", label: "USDT (trc20)" },
  { value: "USDT(erc20)", label: "USDT (erc20)" },
  { value: "USDT(bep20)", label: "USDT (bep20)" },
  // { value: "BTC", label: "Bitcoin" },
];

const Converter = () => {
  const [inputValue, setInputValue] = useState("");
  const [amountToReceive, setAmountToReceive] = useState("");
  const [firstSelect, setFirstSelect] = useState("USD");
  const [secondSelect, setSecondSelect] = useState("USDT(bep20)");
  const [exchangeRates, setExchangeRates] = useState([]);
  const [result, setResult] = useState("");
  const [isBuying, setIsBuying] = useState(true);
  const [btcPrice, setBtcPrice] = useState(null);
  const [lastChanged, setLastChanged] = useState("inputValue");
  const [commission, setCommission] = useState(0);
  const [commissionRate, setCommissionRate] = useState(0);
  let gasFee = 0;
  const fetchExchangeRates = async () => {
    try {
      const response = await fetch(`https://proxy.abcrypto.xyz/proxy`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");
      const rows = doc.querySelectorAll("table.table tbody tr");
      return Array.from(rows).map((row) => {
        const cells = row.querySelectorAll("td");
        const currency = cells[0].innerText.trim();
        const buyRate = parseFloat(cells[1].innerText.trim());
        const sellRate = parseFloat(cells[2].innerText.trim());
        return { currency, buyRate, sellRate };
      });
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      return [];
    }
  };

  const calculateResult = (
    inputValue,
    firstSelect,
    secondSelect,
    isBuying,
    exchangeRates,
    btcPrice
  ) => {
    if (!inputValue || !exchangeRates.length) return "0";

    let resultValue = 0;
    let commissionRate = isBuying ? 0.01 : 0.015;
    gasFee =
      ["USDT(trc20)", "USDT(erc20)"].includes(secondSelect) &&
      inputValue <= 1000
        ? 0
        : 0; //Add 3 to first for gas

    const roundIfNecessary = (value, decimalPlaces = 3) => {
      const [integerPart, decimalPart] = value.toString().split(".");
      if (decimalPart && decimalPart.length > decimalPlaces) {
        return parseFloat(value.toFixed(decimalPlaces));
      }
      return value;
    };
    const customRound = (value, isBuying) => {
      if (isBuying) return value;
      else {
        return Math.floor(value);
      }
    };
    const findRate = (currency, type) =>
      exchangeRates.find((rate) => rate.currency === currency)?.[type];

    const getRate = (currency, adjustment = 0, type = "buyRate") => {
      const rate = (findRate(currency, type) || 0) - adjustment;
      return roundIfNecessary(rate);
    };

    let inputValueInUSD = inputValue;
    if (isBuying) {
      if (firstSelect !== "USD") {
        if (firstSelect === "TRY") {
          inputValueInUSD = inputValue / getRate("USD", -0.1, "sellRate");
        } else {
          const inputValueInTRY =
            inputValue * getRate(firstSelect, 0.1, "buyRate");
          inputValueInUSD = inputValueInTRY / getRate("USD", 0, "sellRate");
        }
      }
    } else {
      if (firstSelect === "BTC") {
        inputValueInUSD = inputValue * btcPrice;
      }
    }

    if (inputValueInUSD <= 250) {
      commissionRate = isBuying ? 0.01 : 0.025;
    } else if (inputValueInUSD <= 999) {
      commissionRate = isBuying ? 0.01 : 0.02;
    }

    let commission = inputValueInUSD * commissionRate;

    if (commission < 1) {
      commission = 1;
    }

    if (firstSelect !== "BTC" && secondSelect !== "BTC") {
      if (isBuying) {
        const conversionRates = {
          "EUR-USDT(trc20)": () =>
            (inputValue * getRate("EUR", 0)) / getRate("USD", -0.1, "sellRate"),
          "EUR-USDT(erc20)": () =>
            (inputValue * getRate("EUR", 0)) / getRate("USD", -0.1, "sellRate"),
          "EUR-USDT(bep20)": () =>
            (inputValue * getRate("EUR", 0)) / getRate("USD", -0.1, "sellRate"),
          "GBP-USDT(trc20)": () =>
            (inputValue * getRate("GBP", 0)) / getRate("USD", -0.1, "sellRate"),
          "GBP-USDT(erc20)": () =>
            (inputValue * getRate("GBP", 0)) / getRate("USD", -0.1, "sellRate"),
          "GBP-USDT(bep20)": () =>
            (inputValue * getRate("GBP", 0)) / getRate("USD", -0.1, "sellRate"),
          "TRY-USDT(trc20)": () =>
            inputValue / getRate("USD", -0.1, "sellRate"),
          "TRY-USDT(erc20)": () =>
            inputValue / getRate("USD", -0.1, "sellRate"),
          "TRY-USDT(bep20)": () =>
            inputValue / getRate("USD", -0.1, "sellRate"),
          "USD-USDT(trc20)": () => inputValue,
          "USD-USDT(erc20)": () => inputValue,
          "USD-USDT(bep20)": () => inputValue,
        };
        resultValue = conversionRates[`${firstSelect}-${secondSelect}`]();
      } else {
        inputValue = inputValue - commission;
        const conversionRates = {
          "USDT(trc20)-EUR": () =>
            (inputValue * getRate("USD", 0.1, "buyRate")) /
            getRate("EUR", 0, "sellRate"),
          "USDT(erc20)-EUR": () =>
            (inputValue * getRate("USD", 0.1, "buyRate")) /
            getRate("EUR", 0, "sellRate"),
          "USDT(bep20)-EUR": () =>
            (inputValue * getRate("USD", 0.1, "buyRate")) /
            getRate("EUR", 0, "sellRate"),
          "USDT(trc20)-GBP": () =>
            (inputValue * getRate("USD", 0.1, "buyRate")) /
            getRate("GBP", 0, "sellRate"),
          "USDT(erc20)-GBP": () =>
            (inputValue * getRate("USD", 0.1, "buyRate")) /
            getRate("GBP", 0, "sellRate"),
          "USDT(bep20)-GBP": () =>
            (inputValue * getRate("USD", 0.1, "buyRate")) /
            getRate("GBP", 0, "sellRate"),
          "USDT(trc20)-TRY": () => inputValue * getRate("USD", 0.1, "buyRate"),
          "USDT(erc20)-TRY": () => inputValue * getRate("USD", 0.1, "buyRate"),
          "USDT(bep20)-TRY": () => inputValue * getRate("USD", 0.1, "buyRate"),
          "USDT(trc20)-USD": () => inputValue,
          "USDT(erc20)-USD": () => inputValue,
          "USDT(bep20)-USD": () => inputValue,
        };
        resultValue = conversionRates[`${firstSelect}-${secondSelect}`]();
        // Only for Euro and Pound
        if (secondSelect === "EUR" || secondSelect === "GBP") {
          resultValue = Math.floor(resultValue);
        }
      }
    } else {
      if (firstSelect === "BTC") {
        //sell btc
        let btcValue = inputValue * btcPrice;
        btcValue = btcValue - commission;
        const conversionRates = {
          "BTC-USD": () => btcValue,
          "BTC-EUR": () =>
            (btcValue * getRate("USD", -0.1, "sellRate")) /
            getRate("EUR", 0, "buyRate"),
          "BTC-GBP": () =>
            (btcValue * getRate("USD", -0.1, "sellRate")) /
            getRate("GBP", 0, "buyRate"),
          "BTC-TRY": () => btcValue * getRate("USD", -0.1, "sellRate"),
        };
        resultValue = conversionRates[`${firstSelect}-${secondSelect}`]();
        if (secondSelect === "EUR" || secondSelect === "GBP") {
          resultValue = Math.floor(resultValue);
        }
      } else if (secondSelect === "BTC") {
        // buy btc
        const conversionRates = {
          "USD-BTC": () => inputValue / btcPrice,
          "EUR-BTC": () =>
            (inputValue * getRate("EUR", 0, "sellRate")) /
            getRate("USD", 0.1, "buyRate") /
            btcPrice,
          "GBP-BTC": () =>
            (inputValue * getRate("GBP", 0, "sellRate")) /
            getRate("USD", 0.1, "buyRate") /
            btcPrice,
          "TRY-BTC": () =>
            inputValue / getRate("USD", 0.1, "buyRate") / btcPrice,
        };
        resultValue = conversionRates[`${firstSelect}-${secondSelect}`]();
      }
    }

    let finalValue = 0;
    setCommission(commission);
    setCommissionRate(commissionRate);
    if (secondSelect !== "BTC") {
      if (!isBuying) finalValue = resultValue;
      else finalValue = resultValue - commission;
      return finalValue.toFixed(2);
    } else {
      finalValue = (inputValueInUSD - commission) / btcPrice;
      return finalValue.toLocaleString(undefined, { minimumFractionDigits: 7 });
    }
  };

  const calculateReverseResult = (
    desiredAmount,
    firstSelect,
    secondSelect,
    isBuying,
    exchangeRates,
    btcPrice
  ) => {
    if (!desiredAmount || !exchangeRates.length) return "0";
    desiredAmount = parseFloat(desiredAmount);
    let commissionRate = isBuying ? 0.01 : 0.015;

    const roundIfNecessary = (value, decimalPlaces = 3) => {
      const [integerPart, decimalPart] = value.toString().split(".");
      if (decimalPart && decimalPart.length > decimalPlaces) {
        return parseFloat(value.toFixed(decimalPlaces));
      }
      return value;
    };

    const findRate = (currency, type) =>
      exchangeRates.find((rate) => rate.currency === currency)?.[type];

    const getRate = (currency, adjustment = 0, type = "buyRate") => {
      const rate = (findRate(currency, type) || 0) - adjustment;
      return roundIfNecessary(rate);
    };

    let AmountToPay = 0;
    if (secondSelect === "USD") {
      AmountToPay = desiredAmount;
      if (AmountToPay <= 250) {
        commissionRate = isBuying ? 0.01 : 0.025;
      } else if (AmountToPay <= 999) {
        commissionRate = isBuying ? 0.01 : 0.02;
      }
    }

    let inputValueInUSD = desiredAmount;
    if (isBuying) {
      if (firstSelect !== "USD") {
        if (firstSelect === "TRY") {
          inputValueInUSD = desiredAmount / getRate("USD", -0.1, "sellRate");
        } else {
          const inputValueInTRY =
            desiredAmount * getRate(firstSelect, 0, "buyRate");
          inputValueInUSD = inputValueInTRY / getRate("USD", -0.1, "sellRate");
        }
      }
    } else {
      if (firstSelect === "BTC") {
        if (secondSelect === "USD") {
          inputValueInUSD = desiredAmount * btcPrice;
        } else if (secondSelect === "TRY") {
          inputValueInUSD = desiredAmount / getRate("USD", -0.1, "sellRate");
        } else {
          const inputValueInTRY =
            desiredAmount * getRate("USD", -0.1, "sellRate");
          inputValueInUSD =
            inputValueInTRY / getRate(secondSelect, 0, "buyRate");
        }
      }
    }

    if (inputValueInUSD <= 250) {
      commissionRate = isBuying ? 0.01 : 0.025;
    } else if (inputValueInUSD <= 999) {
      commissionRate = isBuying ? 0.01 : 0.02;
    }

    let commission = desiredAmount * commissionRate;

    if (commission < 1) {
      commission = 1;
    }
    setCommissionRate(commissionRate);
    if (isBuying) {
      if (secondSelect === "BTC") {
        if (firstSelect === "USD") {
          AmountToPay = desiredAmount * btcPrice;
          if (AmountToPay < 100) {
            commission = 1;
            AmountToPay = AmountToPay + commission;
            setCommission(commission);
          } else {
            AmountToPay = desiredAmount * btcPrice;
            AmountToPay = AmountToPay / ((100 - commissionRate * 100) / 100);
            commission = AmountToPay * commissionRate;

            setCommission(commission);
          }
          return AmountToPay.toFixed(2);
        }
        if (firstSelect === "TRY") {
          // Q1 : Solved
          AmountToPay = desiredAmount * btcPrice;
          if (AmountToPay < 100) {
            commission = 1;
            AmountToPay = AmountToPay + commission;
            setCommission(commission);
            AmountToPay = AmountToPay * getRate("USD", -0.1, "sellRate");
          } else {
            AmountToPay = AmountToPay / ((100 - commissionRate * 100) / 100);
            commission = AmountToPay * commissionRate;
            setCommission(commission);
            AmountToPay = AmountToPay * getRate("USD", -0.1, "sellRate");
          }
          return AmountToPay.toFixed(2);
        } else {
          AmountToPay = desiredAmount * btcPrice;
          if (AmountToPay < 100) {
            commission = 1;
            AmountToPay = AmountToPay + commission;
            setCommission(commission);
            const desiredAmountInTRY =
              AmountToPay * getRate("USD", -0.1, "sellRate");
            AmountToPay =
              desiredAmountInTRY / getRate(firstSelect, 0, "buyRate");
          } else {
            AmountToPay = AmountToPay / ((100 - commissionRate * 100) / 100);
            commission = AmountToPay * commissionRate;
            setCommission(commission);
            const desiredAmountInTRY =
              AmountToPay * getRate("USD", -0.1, "sellRate");
            AmountToPay =
              desiredAmountInTRY / getRate(firstSelect, 0, "buyRate");
          }

          return AmountToPay.toFixed(2);
        }
      } else {
        if (firstSelect === "USD") {
          if (desiredAmount < 100) {
            AmountToPay = desiredAmount + 1;
          } else {
            desiredAmount =
              desiredAmount / ((100 - commissionRate * 100) / 100);
            AmountToPay = desiredAmount;
          }
          setCommission(commission);

          return AmountToPay.toFixed(2);
        }
        if (firstSelect === "TRY") {
          if (desiredAmount < 100) {
            desiredAmount = desiredAmount + 1;
            AmountToPay = desiredAmount * getRate("USD", -0.1, "sellRate");
          } else {
            desiredAmount =
              desiredAmount / ((100 - commissionRate * 100) / 100);
            AmountToPay = desiredAmount * getRate("USD", -0.1, "sellRate");
          }
          setCommission(commission);

          return AmountToPay.toFixed(2);
        } else {
          if (desiredAmount < 100) {
            desiredAmount = desiredAmount + 1;
            const desiredAmountInTRY =
              desiredAmount * getRate("USD", -0.1, "sellRate");
            AmountToPay =
              desiredAmountInTRY / getRate(firstSelect, 0, "buyRate");
          } else {
            desiredAmount =
              desiredAmount / ((100 - commissionRate * 100) / 100);
            const desiredAmountInTRY =
              desiredAmount * getRate("USD", -0.1, "sellRate");
            AmountToPay =
              desiredAmountInTRY / getRate(firstSelect, 0, "buyRate");
          }
          setCommission(commission);

          return AmountToPay.toFixed(2);
        }
      }
    }
    // sell
    else {
      if (firstSelect === "BTC") {
        if (secondSelect === "USD") {
          if (desiredAmount < 39) {
            commission = 1;
            AmountToPay = desiredAmount / btcPrice;
            AmountToPay = AmountToPay + commission / btcPrice;
            setCommission(commission);
          } else {
            AmountToPay = desiredAmount / ((100 - commissionRate * 100) / 100);
            commission = AmountToPay * commissionRate;
            AmountToPay = AmountToPay / btcPrice;
            setCommission(commission);
          }
          return AmountToPay.toFixed(6);
        }
        if (secondSelect === "TRY") {
          desiredAmount = desiredAmount / getRate("USD", 0.1, "buyRate");
          if (desiredAmount < 39) {
            commission = 1;
            AmountToPay = desiredAmount / btcPrice;
            AmountToPay = AmountToPay + commission / btcPrice;
            setCommission(commission);
          } else {
            AmountToPay = desiredAmount * ((100 - commissionRate * 100) / 100);
            commission = desiredAmount * commissionRate;
            AmountToPay = AmountToPay / btcPrice;
            setCommission(commission);
          }
          return AmountToPay.toFixed(6);
        } else {
          const desiredAmountInTRY =
            desiredAmount * getRate(secondSelect, 0, "sellRate");
          desiredAmount = desiredAmountInTRY / getRate("USD", 0.1, "buyRate");
          if (desiredAmount < 39) {
            commission = 1;
            AmountToPay = desiredAmount / btcPrice;
            AmountToPay = AmountToPay + commission / btcPrice;
            setCommission(commission);
          } else {
            commission = desiredAmount * commissionRate;
            console.log("🚀 ~ Converter ~ commission:", commission);
            AmountToPay =
              (desiredAmount / btcPrice) * ((100 - commissionRate * 100) / 100);

            // AmountToPay = AmountToPay + commission / btcPrice;
            setCommission(commission);
          }
          return AmountToPay.toFixed(6);
        }
      } else {
        if (secondSelect === "USD") {
          if (desiredAmount < 39) {
            commission = 1;
            AmountToPay = desiredAmount + commission;
            setCommission(commission);
          } else {
            desiredAmount =
              desiredAmount / ((100 - commissionRate * 100) / 100);
            AmountToPay = desiredAmount;
            commission = AmountToPay * commissionRate;
            setCommission(commission);
          }
          return AmountToPay.toFixed(2);
        }
        if (secondSelect === "TRY") {
          desiredAmount = desiredAmount / getRate("USD", 0.1, "buyRate");
          if (desiredAmount <= 250) {
            commissionRate = isBuying ? 0.01 : 0.025;
          } else if (desiredAmount <= 999) {
            commissionRate = isBuying ? 0.01 : 0.02;
          } else {
            commissionRate = isBuying ? 0.01 : 0.015;
          }
          if (desiredAmount < 39) {
            commission = 1;
            AmountToPay = desiredAmount + commission;
            setCommission(commission);
          } else {
            AmountToPay = desiredAmount / ((100 - commissionRate * 100) / 100);
            commission = AmountToPay * commissionRate;
            setCommission(commission);
          }
          setCommissionRate(commissionRate);
          return AmountToPay.toFixed(2);
        } else {
          const desiredAmountInTRY =
            desiredAmount * getRate(secondSelect, 0, "sellRate");
          desiredAmount = desiredAmountInTRY / getRate("USD", 0.1, "buyRate");
          if (desiredAmount <= 250) {
            commissionRate = isBuying ? 0.01 : 0.025;
          } else if (desiredAmount <= 999) {
            commissionRate = isBuying ? 0.01 : 0.02;
          } else {
            commissionRate = isBuying ? 0.01 : 0.015;
          }

          setCommissionRate(commissionRate);
          if (desiredAmount < 39) {
            commission = 1;
            AmountToPay = desiredAmount + commission;
            setCommission(commission);
          }

          AmountToPay = desiredAmount / ((100 - commissionRate * 100) / 100);
          commission = AmountToPay * commissionRate;

          setCommission(commission);
          return AmountToPay.toFixed(2);
        }
      }
    }
  };

  useEffect(() => {
    const fetchBtcPrice = async () => {
      try {
        const response = await fetch("https://proxy.abcrypto.xyz/api/crypto");
        const data = await response.json();
        const price = data.data.BTC.quote.USD.price;
        setBtcPrice(price);
        console.log("Bitcoin price:", price);
      } catch (error) {
        console.error("Error fetching Bitcoin price:", error);
      }
    };

    fetchBtcPrice();

    const interval = setInterval(fetchBtcPrice, 900000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchRates = async () => {
      const rates = await fetchExchangeRates();
      setExchangeRates(rates);
    };
    fetchRates();
  }, []);

  useEffect(() => {
    if (!btcPrice || exchangeRates.length === 0) return;

    if (lastChanged === "inputValue") {
      const updatedResult = calculateResult(
        inputValue,
        firstSelect,
        secondSelect,
        isBuying,
        exchangeRates,
        btcPrice
      );
      setResult(updatedResult);
      setAmountToReceive(updatedResult);
    } else if (lastChanged === "amountToReceive") {
      const revValue = calculateReverseResult(
        amountToReceive,
        firstSelect,
        secondSelect,
        isBuying,
        exchangeRates,
        btcPrice
      );
      setInputValue(revValue);
      setResult(amountToReceive);
    }
  }, [
    inputValue,
    amountToReceive,
    firstSelect,
    secondSelect,
    isBuying,
    exchangeRates,
    btcPrice,
    lastChanged,
  ]);

  const filteredRates = exchangeRates
    .filter((rate) => ["USD", "EUR", "GBP"].includes(rate.currency))
    .map((rate) => {
      if (rate.currency === "USD") {
        return {
          ...rate,
          buyRate: (rate.buyRate - 0.1).toFixed(2),
          sellRate: (rate.sellRate + 0.1).toFixed(2),
        };
      }
      rate.buyRate = Number(rate.buyRate).toFixed(2);
      rate.sellRate = Number(rate.sellRate).toFixed(2);

      return rate;
    });

  const handleSwitch = () => {
    setIsBuying(!isBuying);
    setFirstSelect(isBuying ? "USDT(trc20)" : "USD");
    setSecondSelect(isBuying ? "USD" : "USDT(trc20)");
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setLastChanged("inputValue");
  };

  const handleAmountChange = (event) => {
    setAmountToReceive(event.target.value);
    setLastChanged("amountToReceive");
  };

  const toggleBuySell = (buying) => {
    setIsBuying(buying);
    setFirstSelect(isBuying ? "USDT(trc20)" : "USD");
    setSecondSelect(isBuying ? "USD" : "USDT(trc20)");
  };

  return (
    <div className="converter-container">
      {/* <div className="container">
        <Card
          className="crypto-card"
          title={
            <h1>
              <RiCoinsLine /> Crypto Converter
            </h1>
          }
        >
          <div className="toggle-button">
            <Button
              className="but"
              type={isBuying ? "primary" : "default"}
              onClick={() => toggleBuySell(true)}
            >
              Buy Crypto
            </Button>
            <Button
              className="but"
              type={!isBuying ? "primary" : "default"}
              onClick={() => toggleBuySell(false)}
            >
              Sell Crypto
            </Button>
          </div>

          <br />
          <Form size="large">
            <Form.Item>
              <p>You {isBuying ? "pay" : "send"}</p>
              <Select
                size="large"
                value={firstSelect}
                onChange={setFirstSelect}
                options={isBuying ? fiatList : cryptoList}
              />
              <br />
              <br />
              <Input
                placeholder="Amount"
                type="number"
                value={inputValue}
                onChange={handleInputChange}
              />
              <br />
              <br />
              <hr />
              <p>To Receive</p>
              <Select
                size="large"
                value={secondSelect}
                onChange={setSecondSelect}
                options={isBuying ? cryptoList : fiatList}
              />
              <br />
              <br />
              <Input
                placeholder="Amount to Receive"
                type="number"
                value={amountToReceive}
                onChange={handleAmountChange}
              />
            </Form.Item>
          </Form>
          <p>
            {firstSelect === "BTC" || secondSelect === "BTC"
              ? `Estimated Amount you receive:  ${result} ${secondSelect}`
              : `Amount you receive: ${result} ${secondSelect}`}
          </p>
          <p style={{ color: "green", fontSize: "16px" }}>
            {`Commission Rate: ${
              commissionRate * 100
            }% | Our Commision: $${commission.toFixed(2)}`}
          </p>
          <p style={{ color: "red", fontSize: "14px" }}>
            {"Note: Minimum commission is $1"}
          </p>
          <p style={{ color: "#6b6b6b", fontSize: "14px" }}>
            {secondSelect === "USDT(trc20)" || secondSelect === "USDT(erc20)"
              ? "*Additionally gas fees maybe charged"
              : ""}
          </p>
        </Card>
      </div> */}
      <div className="container-right">
        <Card
          className="rate-card"
          title={
            <h2>
              <RiCoinsLine /> Exchange Rates
            </h2>
          }
        >
          <div className="exrate">
            <div className="row header">
              <div className="col-5">
                <strong>Currency</strong>
              </div>
              <div className="col-3">
                <strong>We Buy</strong>
              </div>
              <div className="col-4">
                <strong>We Sell</strong>
              </div>
            </div>
            {filteredRates.map((rate, index) => (
              <div className="row" key={index}>
                <div className="col-5">
                  <img
                    src={currencyFlags[rate.currency]}
                    alt={`${rate.currency} flag`}
                    className="currency-flag"
                  />
                  {rate.currency}
                </div>
                <div className="col-3">{rate.buyRate}</div>
                <div className="col-4">{rate.sellRate}</div>
              </div>
            ))}
          </div>{" "}
        </Card>
      </div>
    </div>
  );
};

export default Converter;
